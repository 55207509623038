const petete = Object.freeze({
    getPrj: () => {
        const path = window.location.hostname;
        let ret = "tgd"; // Valor default
        
        if (path.includes("console")) { // Si contiene "console", cogemos lo que tenga despues de "console."
            const parts = path.split(".");
            if (parts.length > 1) {
                ret = parts[1]; // se divide substr por el punto y cogemos el segundo elemento del arr
            }
        } else { // Si no, extraemos el primer fragemto del host antes del primer punto
            ret = path.match(/^[^.]+/) ? path.match(/^[^.]+/)[0] : "tgd";
            
            // Validamos el proyecto segun lo que hayamos extraido: 
            if (ret.indexOf('-') > -1) ret = ret.substring(0, ret.indexOf('-')); // Si hay un guion (`-`), tomar solo la parte antes del guion
            if (ret == 'panel' || ret == 'kaps' || ret == 'test') ret = 'tgd'; // Estos subdominios específicos se traducen a "tgd"
            if (ret == 'snowfy') ret = 'snowfy'; // "snowfy" o "console" se traducen a "snowfy" (aunque aquí "console" no se aplica porque ya está en el primer bloque)
        
        // if(ret.indexOf('-t3st')>-1) ret = ret.substring(0, ret.indexOf('-t3st'));
        // if(ret.indexOf('-helpy')>-1) ret = ret.substring(0, ret.indexOf('-helpy'));
        // if(ret.indexOf('.helpyplus')>-1) ret = ret.substring(0, ret.indexOf('.helpyplus'));
        // if(ret.indexOf('.helpysafe')>-1) ret = ret.substring(0, ret.indexOf('.helpysafe'));
        }
// console.log(ret)
        return ret;
    }
});

module.exports = petete;

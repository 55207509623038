import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Menu } from 'antd';
import {
  MenuFoldOutlined,
  LogoutOutlined,
  // LoginOutlined,
  AlertOutlined,
  MenuOutlined,
  LockOutlined,
  StopOutlined,
  AimOutlined,
  ClusterOutlined,
  SettingOutlined,
  UserOutlined,
  AppstoreOutlined,
  HeatMapOutlined
} from "@ant-design/icons";
import '../assets/css/menu.css'
import { useTranslation } from 'react-i18next';
import { logOut } from '../context/firebaseConfig';
import { useFirebaseAuth } from '../context/AuthContext';

const MyMenu = ({history}) => {
  const [user] = useState(useFirebaseAuth())
  const nav = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const [menuKey, setMenuKey] = useState();
  const [rol, setRol] = useState();
  const [service, setService] = useState();
  const [menuItems, setMenuItems] = useState([]);

  useEffect(()=>{
    const path = location?.pathname?.replace(/\//g, '') || menuKey;
    setMenuKey(path);
  },[location.pathname])

  useEffect(()=>{
    const its = [];
    let unsubscribe = null;
    if(user) {
      setRol(user.rol);
      setService(user.service);
      if(user?.rol.indexOf('ADMIN') > -1 || user?.rol.indexOf('MANAGEMENT') > -1) {
          its.push(getItem(t('BUTTON_DASHBOARD'), 'dashboard', <AppstoreOutlined key='menu_dashboard'/>));
          its.push(getItem(t('BUTTON_CONFIG_PANEL'), 'pittpanel', <SettingOutlined key='menu_config'/>));
          its.push(getItem(t('BUTTON_SIMULATOR_PANEL'), 'simulatorpanel', <ClusterOutlined key='menu_simulator'/>));
          its.push(getItem(t('BUTTON_USER_TRAVELS'), 'usertravels', <UserOutlined key='menu_user_travels'/>));
          its.push(getItem(t('BUTTON_HEATMAP'), 'heatmap', <HeatMapOutlined key='menu_heatmap'/>));
      }
      if(user.rol?.indexOf('ADMIN') > -1 || user.rol?.indexOf('OPERATOR') > -1) {
        its.push(getItem(t('BUTTON_ALERTS'), 'alerts', <AlertOutlined key='menu_alerts'/>));
        its.push(getItem(t('BUTTON_CANCELLED'), 'cancelled', <StopOutlined key='menu_cancelled'/>));
        its.push(getItem(t('BUTTON_CLOSED'), 'closed', <LockOutlined key='menu_closed'/>));
        its.push(getItem(t('BUTTON_MAPPED_ALERTS'), 'mapped', <AimOutlined key='menu_mapped'/>));
      }
      its.push(getItem(t('BUTTON_LOGOUT'), 'logout', <LogoutOutlined key='menu_logout'/>));
      
    }
    // } else {
    //   its.push(getItem(t('BUTTON_LOGIN'), 'login', <LoginOutlined key='menu_login'/>));
    // }
    setMenuItems(its);
    return unsubscribe;
  }, [user]);


  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const getItem = (label, key, icon, children, type) => {
    return { key, icon, children, label, type };
  }

  const petete = ({ item, key, keyPath, domEvent }) => {
    if(keyPath == 'logout') logOut();
    else nav(`/${keyPath[0]}`);
  }

  return(   
    menuItems.length > 0 &&
    <div style={{ width: 'auto' }} key='main_menu'>

      <Button
      key='menu_button'
      type="ghost"
      onClick={toggleCollapsed}
      style={{ margin: '5px 0 0 20px' }}
      >
        {React.createElement(
          collapsed ? MenuOutlined : MenuFoldOutlined
          )}
      </Button>
      <Menu
        style={{ backgroundColor: '#fffbfb', borderRight: "none" }}
        mode="inline"
        inlineCollapsed={collapsed}
        key='menu'
        defaultSelectedKeys={[menuKey]}
        items={menuItems}
        onClick={petete}
      />
    </div>
  )
};

export default MyMenu;
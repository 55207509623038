import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import { Chart } from "react-google-charts";

import { getWatchingHook, getWatchingReport, getWatchingImpacts, closeAlert, updateDocu, getDocu } from "../context/firebaseConfig";
import { useFirebaseAuth } from "../context/AuthContext";

import { Card, Button, Modal, Select, Form, Spin, message } from "antd";
import TextArea from "antd/es/input/TextArea";
import {
    CloseCircleFilled,
    InfoCircleFilled,
    InfoCircleOutlined,
    DashboardFilled,
    DashboardOutlined,
    AreaChartOutlined,
    LineChartOutlined,
    AudioFilled,
    AudioOutlined,
    PlusCircleFilled,
    MinusCircleOutlined,
    CopyOutlined
} from "@ant-design/icons";

import '../assets/css/watchAlert.css'

import { useGoogleMaps } from '../context/GoogleMapsContext';

const { Option } = Select;

const containerStyle = {
    width: '100%',
    height: '60vh'
};


const WatchAlert = (props) => {
    const [user] = useState(useFirebaseAuth());
    const [data] = useState(useLocation().state.data);
    const { t } = useTranslation();
    const type = useLocation().state.type;
    const nav = useNavigate();
    const [form] = Form.useForm();

    const [modalState, setModalState] = useState(false);

    const [locations, setLocations] = useState(useLocation().state.data.locations);
    const [reported, setReported] = useState([])
    const [markers, setMarks] = useState([])

    const { alertId, phone } = useLocation().state.data;
    const [center] = useState({ lat: data.latitude || data.lat, lng: data.longitude || data.long || data.lng })

    const [canceled, setCanceled] = useState(false);

    const [resume, setResume] = useState(false);

    const [report, setReport] = useState(null);
    const [impactData, setImpactData] = useState(['', '']);

    const [impacts, setImpacts] = useState(false);
    const [chart, setChart] = useState(false);
    const [audios, setAudios] = useState(false);
    const [showAudios, setShowAudios] = useState(useLocation().state.data.audioUrls?.length > 0);
    const [arrAudios, setArrAudios] = useState([]);
    const [chartRendered, setChartRendered] = useState(null);
    const [chartSpeeds, setChartSpeed] = useState(null);

    const [closingAlert, setClosingAlert] = useState(false);

    const [customMap, setCustomMap] = useState(null);
    const [mapType, setMapType] = useState();
    const mapRef = useRef(null);
    
    const { isLoaded } = useGoogleMaps();

    const [name, setName] = useState(null);

    useEffect(() => {
        // if(type === 'alerts') { 
        // //Cambio este if a solo la linea setCanceled  L-87 que muestra en rojo un Cancelada que bloquea la app
        const unsubscribe = getWatchingHook({ coll: type, alertId: `${alertId}${phone}` },
            (querySnapshot) => {
                const d = querySnapshot.data()
                const lls = d.locations;
                if (type === 'alerts') { setCanceled(d.status !== 0) };
                setLocations(lls);
                setShowAudios(d?.audioURLs?.length > 0 || d?.audioUrls?.length > 0);
                setArrAudios(d?.audioURLs || d?.audioUrls)
            },
            (error) => alert('kk de la vaca')
        );
        return unsubscribe;
        // } 
    }, [setLocations]);

    useEffect(() => {
        async function readReport() {
            const querySnapshot = await getWatchingReport({ alertId: `${alertId}${phone}` })
            const data = querySnapshot;
            setReport(data);
            if (data?.report?.locations) {
                setReported(data?.report?.locations);
                const speeds = [['', ''], ...data.report.locations.map(l => ['', l.speed])];
                setChartSpeed(
                    <Chart
                        chartType="LineChart"
                        data={speeds}
                        options={{
                            legend: "none",
                        }}
                    />
                );
            }
            const dbUser = await getDocu({coll:'users', docu: `${phone}`})
            setName(`${dbUser.name ? dbUser.name + ' ' : ''}${dbUser.surname ? dbUser.surname : ''}`);
            await updateDocu({ coll: type, docu: `${alertId}${phone}`, data: { viewed: true } });
        }
        readReport();
        return;
    }, []);

    useEffect(() => {
        async function readImpacts() {
            const querySnapshot = await getWatchingImpacts({ hasAccelerations: report?.hasAccelerations, alertId: `${alertId}${phone}`, impacts: chartRendered != null })
            const data = querySnapshot;
            if (data?.length > 0) {
                data.unshift([{ type: 'string', label: '' }, ''])
                setImpactData(data);
                setChartRendered(
                    <Chart
                        chartType="LineChart"
                        data={data} // Los datos ya vienen de `setImpactData`
                        options={{
                            legend: "none",
                            hAxis: { textPosition: 'none' }, // Ocultamos texto en eje X
                        }}
                    />
                );
            }
        }
        readImpacts();
    }, [report?.hasAccelerations]);

    useEffect(() => {
        const origin = {...center, zeroPoint: true };
        setMarks([...reported, origin, ...locations || []]);
        setResume(true);
        setAudios(true);
        setChart(true);
        setImpacts(true);

    }, [reported, locations, setLocations])

    useEffect(() => {
        setCustomMap(user?.configs?.customMap);  
        setMapType(user?.configs?.mapType);   
    }, [user])

    const handleSubmit = async (e) => {
        // reason, description
        setClosingAlert(true);
        const ret = await closeAlert({
            id: `${alertId}${phone}`,
            status: e.reason || 103,
            comment: e.description || '',
            tk: user?.accessToken,
            service: user?.service
        });
        form.resetFields();
        setClosingAlert(false);
        nav(-1);
    }

    const getDot = (l) => {
        // console.log(l)
        const dot = !l.data ? l.zeroPoint || l.crash ? 'red' : 'yellow' : 'blue';
        // console.log(!l.data && l.crash, dot)
        return dot;
    }

    const parseDate = (r) => {
        const dateObj = new Date(r);
        const y = dateObj.getFullYear();
        const m = String(dateObj.getMonth() + 1).padStart(2, '0'); //+1 pq meses van de 0 a 11
        const d = String(dateObj.getDate()).padStart(2, '0');
        const hh = String(dateObj.getHours()).padStart(2, '0');
        const mm = String(dateObj.getMinutes()).padStart(2, '0');
        const ss = String(dateObj.getSeconds()).padStart(2, '0');

        const fd = `${y}-${m}-${d}, ${hh}:${mm}:${ss}`;
        return fd;
    }

    const onLoad = (map) => {
        mapRef.current = map;
        if (mapRef.current && customMap) {
            const map = mapRef.current;
      
            // Define las coordenadas de los límites de la imagen
            const imageBounds = {
              north: customMap.north,
              south: customMap.south,
              east: customMap.east,
              west: customMap.west
            };
      
            // Crea el GroundOverlay
            const groundOverlay = new window.google.maps.GroundOverlay(
              customMap.image, // Reemplaza con la URL de tu imagen GIF
              imageBounds
            );
      
            // Agrega el GroundOverlay al mapa
            groundOverlay.setMap(map);
        }
    };

console.log(useLocation());    
    return (
        <React.Fragment>
            <div className="watch-alert-container">
                {/* *** MAPA *** */}
                <div className="map-container">
                    {isLoaded &&
                        <GoogleMap
                            mapTypeId={mapType}
                            mapContainerStyle={containerStyle}
                            center={center}
                            zoom={15}
                            onLoad={onLoad}
                            //onUnmount={onUnmount}
                        >
                            {   //si no hay markers tenemos que mostrar la posición para el SOS
                                markers.length < 1 ?
                                    (<Marker
                                        position={{
                                            lat: data.latitude || data.lat || data.data?.latitude || data.data?.lat,
                                            lng: data.longitude || data.long || data.lng || data.data?.longitude || data.data?.long || data.data?.lng
                                        }}
                                        key={'markIni'}
                                        gmpClickable={false}
                                    />) : (<></>)
                            }
                            {markers.map((l, i) => (
                                <Marker
                                    position={{
                                        lat: l.latitude || l.lat || l.data?.latitude || l.data?.lat,
                                        lng: l.longitude || l.long || l.lng || l.data?.longitude || l.data?.long || l.data?.lng
                                    }}
                                    icon={`/assets/img/${getDot(l)}-dot.png`}
                                    key={i}
                                />
                            ))}
                        </GoogleMap>
                    }
                </div>

                {/* *** BLOQUE DE TARJETAS *** */}
                <div className="cards-wrapper" >
                    {/* 1.-Tarjeta Resumen */}
                    {resume &&
                        <Card title={t('CARD_TITLE_RESUME')} style={{ borderRadius: '15px', margin: '0 5px 5px 0', overflow: 'auto' }} bordered
                            extra={<CloseCircleFilled className="card-close-icon" onClick={() => setResume(!resume)} />} >

                            <p><i>{t('Id')}:</i> {alertId}</p>

                            <p><i>{t('LABEL_PHONE')}:</i> {phone}</p>
                            {name && <p><i>{t('LABEL_SIMULATOR_CONFIG_NAME')}:</i> {name}</p>}

                            {/* ESTE LABEL DATE ES EL QUE NOS SALE CON LA HORA MAL Y CORREGIMOS CON LINEA SIGUIENTE */}
                            {/* <p><i>{t('LABEL_DATE')}:</i> {new Date(alertId).toISOString().replace('T', ' - ')}</p> */}
                            <p><i>{t('LABEL_DATE')}:</i> {parseDate(alertId)}</p>

                            {/* TODO:DUDA Las dos siguientes lineas muestran la misma info, pero de manera distinta ¿Cual dejamos? La primera muestra siempre el Label y el valor de severity o cero. Mientras que la segunda opcion lo que hace es mostrar toda la linea o no en funcion de si hay severity  */}
                            {/*<p><i>{t('LABEL_SEVERITY')}:</i> {report?.severity && report.severity}</p>*/}
                            {/* {(report?.severity && <p><i>{t('LABEL_SEVERITY')}:</i> {report.severity}</p> )|| ''}  */}

                            {report?.noiseLevel && <p><i>{t('LABEL_AMBIENT_SOUND')}:</i> {report.noiseLevel}</p>}

                            {/* El siguiente elemento es el que tiene la funcionalidad para copiar en el portapapeles las coordenadas */}
                            <p><i>{t('LABEL_COORDS')}:</i> {data.lat.toFixed(6)} , {data.long.toFixed(6)}{" "}
                                <CopyOutlined
                                    style={{ cursor: 'pointer', color: '#1890ff', marginLeft: '5px' }}
                                    onClick={() => {
                                        const coords = `${data.lat.toFixed(6)}, ${data.long.toFixed(6)}`;
                                        const textArea = document.createElement('textarea');
                                        textArea.value = coords;
                                        document.body.appendChild(textArea);
                                        textArea.select();
                                        try {
                                            document.execCommand('copy'); //en desuso, sigue siendo funcional en la mayoría de los navegadores, en un futuro podría eleiminarse y habria que rehacer esta parte TODO:
                                            message.success('¡Coordenadas copiadas al portapapeles!', 2); // Mensaje OK AntD
                                        } catch (err) {
                                            console.error('Error al copiar las coordenadas:', err);
                                            message.error('No se pudieron copiar las coordenadas.', 2); // Mensaje KO AntD
                                        }
                                        document.body.removeChild(textArea);
                                    }}
                                />                                
                            </p>

                            {/* Las dos siguientes lineas informarán del motivo de cancelación (NO IMPLEMENTADO en server TODO:) o el motivo de cierre de dicha alerta.  */}
                            {data?.cancelReason && <p><i>{t('LABEL_CANCEL')}:</i> {data.cancelReason}</p>}
                            {data?.comment && <p><i>{t('LABEL_CLOSE')}:</i> {data.comment}</p>}
                        </Card>
                    }

                    {/* 2.-Tarjeta Impactos: grafica + datos*/}
                    {impacts &&
                        <Card title={t('CARD_TITLE_IMPACTS')} style={{ height: 'auto', borderRadius: '15px', margin: '0 5px 5px 0' }}
                            extra={<CloseCircleFilled className="card-close-icon" onClick={() => setImpacts(!impacts)} />} >
                            <div className="impact box" >
                                {chartRendered || <p className="no-data-text">{t('LABEL_NO_DATA_AVAILABLE')}</p>}
                                <div style={{ marginTop: 10 }}>
                                    <p><i>{t('LABEL_PEAKS')}:</i> {report?.impactPeaks ? report.impactPeaks : 0}</p>
                                    {report?.maxG != 0 ?
                                        report?.maxG && <p><i>{t('LABEL_INTENSITY')}:</i> {report.maxG.toFixed(1)}</p>
                                        : ""}
                                    <p><i>{t('LABEL_FLIGHT')}:</i> {report?.flight ? "Si" : "No"}</p>
                                </div>
                            </div>
                        </Card>
                    }

                    {/* 3.-Tarjeta Velocidad y distancia: grafica + datos*/}
                    {chart &&
                        <Card title={t('CARD_TITLE_CHART')} style={{ height: 'auto', borderRadius: '15px', margin: '0 5px 5px 0' }}
                            extra={<CloseCircleFilled className="card-close-icon" onClick={() => setChart(!chart)} />} >
                            <div className="speedDistance box">
                                {chartSpeeds || <p className="no-data-text">{t('LABEL_NO_DATA_AVAILABLE')}</p>}

                                {/* TODO: si no hay datos aparece la m de los metros, no es algo relevante, pero anotado */}
                                <p><i>{t('LABEL_DISTANCE_BEFORE')}:</i> {report?.preAccidentDistance.toFixed(2)}m.</p>
                                <p><i>{t('LABEL_DISTANCE_AFTER')}:</i> {report?.accidentDistance.toFixed(2)}m.</p>
                                {report?.altitudeImpact && report?.altitudePre && report?.altitudePost &&
                                    <p>
                                        <i>{t('LABEL_ALT_INI')}=</i> {Number(report.altitudePre).toFixed(0)}m.<br />
                                        <i>{t('LABEL_ALT_IMPACT')}=</i> {Number(report.altitudeImpact).toFixed(0)}m.<br />
                                        <i>{t('LABEL_ALT_END')}=</i> {Number(report.altitudePost).toFixed(0)}m.<br />
                                        <i>{t('LABEL_ALT_DIFF')}=</i> {Number(report.altitudePre - report.altitudePost).toFixed(0)}m.
                                    </p>}
                            </div>
                        </Card>
                    }

                    {/* 4.-Tarjeta Audios */}
                    {showAudios ? audios &&
                        <Card title={t('CARD_TITLE_AUDIOS')} style={{ height: 'auto', borderRadius: '15px', margin: '0 5px 5px 0' }}
                            extra={<CloseCircleFilled className="card-close-icon" onClick={() => setAudios(!audios)} />} >
                            {/* {console.log(arrAudios)}
                            {arrAudios.map((audio) => (
                                <p key={audio.id}>{audio.data}</p>
                            ))} */}
                            {arrAudios.map((audio) => {
                                const recordingTime = new Date(audio.time).toLocaleTimeString([], {
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    second: '2-digit',
                                });
                                return (
                                    <div key={audio.id} style={{ marginBottom: '5px' }}>
                                        <label style={{ fontWeight: 'bold' }}>{recordingTime}</label>
                                        <audio controls>
                                            <source src={audio.data} type="audio/mpeg" />
                                            Tu navegador no soporta el elemento de audio.
                                        </audio>
                                    </div>
                                );
                            })}
                        </Card>
                        : ''
                    }
                </div>
            </div>

            {/* *** MENU PARA DESPLEGAR/OCUTAR TARJETAS *** */}
            <div className="cards-menu-wrapper">
                {resume && impacts && chart && audios ? <MinusCircleOutlined className="card-menu-icon" onClick={() => {
                    setResume(false); setImpacts(false); setChart(false); if (showAudios) setAudios(false)
                }} /> :
                    <PlusCircleFilled className="card-menu-icon" onClick={() => {
                        setResume(true); setImpacts(true); setChart(true); if (showAudios) setAudios(true);
                    }} />}
                {resume ? <InfoCircleFilled className="card-menu-icon" onClick={() => setResume(!resume)} /> :
                    <InfoCircleOutlined className="card-menu-icon" onClick={() => setResume(!resume)} />}
                {impacts ? <AreaChartOutlined className="card-menu-icon" onClick={() => setImpacts(!impacts)} /> :
                    <LineChartOutlined className="card-menu-icon" onClick={() => setImpacts(!impacts)} />}
                {chart ? <DashboardFilled className="card-menu-icon" onClick={() => setChart(!chart)} /> :
                    <DashboardOutlined className="card-menu-icon" onClick={() => setChart(!chart)} />}
                {showAudios ?
                    audios ? <AudioFilled className="card-menu-icon" onClick={() => setAudios(!audios)} /> :
                        <AudioOutlined className="card-menu-icon" onClick={() => setAudios(!audios)} />
                    : ''
                }
            </div>

            {/* *** ? *** */}
            {canceled &&
                <div className="blink-wrap" >
                    <div className="blink">
                        <a onClick={() => { nav(-1); }} style={{ color: 'black' }}><span>Cancelada</span></a>
                    </div>
                </div>
            }

            {/* *** BOTON ASPA CERRAR DETALLE Y (si es alerta) BOTON CERRAR ALERTA+FORMULARIO *** */}
            <div className="actions-menu-wrapper">
                {type === 'alerts' &&
                    <button className="btnAction btActionCloseAlert" onClick={() => { setModalState(true); }}>{t('BUTTON_CLOSE_ALERT')}</button>
                }
                <button className="btnAction btActionClosePage" onClick={() => { nav(-1); }}>X</button>
            </div>
            <Modal
                open={modalState}
                title={t('CLOSE_CONFIRM_TEXT')}
                footer={
                    closingAlert ?
                        [<Spin size="large" />]
                        :
                        [<Button key="back" onClick={() => { setModalState(false); }}>
                            {t('BUTTON_CANCEL')}
                        </Button>,
                        <Button key="submit" type="primary" onClick={() => form.submit()}>
                            {t('BUTTON_OK')}
                        </Button>]
                }
            >
                <Form form={form} onFinish={handleSubmit} layout="vertical">
                    <Form.Item label={t('LABEL_CAUSE_SELECTOR')} name='reason'>
                        <Select defaultValue="103" disabled={closingAlert}>
                            <Option value="200">{t('CONFIRMED_COLISION')}</Option>
                            <Option value="103" selected>{t('UNCONFIRMED_COLISION')}</Option>
                            <Option value="102">{t('FAKE_COLISION')}</Option>
                            <Option value="101">{t('FAKE_DATA')}</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label={t('LABEL_CLOSE_DESCRIPTION')} name='description'>
                        <TextArea rows={4} disabled={closingAlert} />
                    </Form.Item>
                </Form>
            </Modal>
        </React.Fragment>
    )
}

export default WatchAlert;
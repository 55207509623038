const BDS = {
    "baike": {
        "apiKey": "AIzaSyBgMBgNXUnsPPmkFIdW-iWzHN_1a7h0t3o",
        "authDomain": "baike-kaps-v2.firebaseapp.com",
        "projectId": "baike-kaps-v2",
        "appId": "1:268140893986:web:8f08063cff0058b0a6b77b",
    },
    "drz":{
        "apiKey": "AIzaSyBqpuEOoY6wyR1M3QVggbMkKSqlqht4J0o",
        "authDomain": "draivez.firebaseapp.com",
        "projectId": "draivez",
        "appId": "1:661222433971:web:0caa381b022335acf87cc6",
    },
    "pus": { 
        "apiKey": "AIzaSyCYHFGoFsKwrhIkWJLtoqO72rhNI1EFp2E",
        "authDomain": "autohelpplus.firebaseapp.com",
        "projectId": "autohelpplus",
        "appId": "1:397303597018:web:bbda8762dcd33a0e20a2e3",
    },
    "ahp": {
        "apiKey": "AIzaSyCYHFGoFsKwrhIkWJLtoqO72rhNI1EFp2E",
        "authDomain": "autohelpplus.firebaseapp.com",
        "projectId": "autohelpplus",
        "appId": "1:397303597018:web:bbda8762dcd33a0e20a2e3",
    },    
    "rep": {
        "apiKey": "AIzaSyCTOLODmKp3o2p4DKcuqXMV0CxZLbooqC8",
        "authDomain": "remotoplus-kaps.firebaseapp.com",
        "projectId": "remotoplus-kaps",
        "appId": "1:284815132732:web:08ed31cbe5401425cd5b39",
    },
    "api-kaps": {
        "apiKey": "AIzaSyCIH1HtwhESYQoz9_JDLDFqxIsXz1YfELw",
        "authDomain": "api-kaps.firebaseapp.com",
        "projectId": "api-kaps",
        "appId": "1:222654951856:web:ee3a8496bc560831d2a675"
    },
    "tgd": {
        "apiKey": "AIzaSyAB0pH-vHsji3_tR4xqEl8ZaZ7xvZ0ZOEI",
        "authDomain": "the-good-driver.firebaseapp.com",
        "projectId": "the-good-driver",
        "appId": "1:258587131765:web:568bc9a70085482e2c1d3c"
    },
    "snowfy": {
        "apiKey": "AIzaSyBQrwegYWQEmOBBsdgv8qbfR7bv0SyP9lo",
        "authDomain": "snowfy-kaps.firebaseapp.com",
        "projectId": "snowfy-kaps",
        "appId": "1:365142813166:web:f192d14ced5f5f692106ff"
    },
    "remoto": {
        "apiKey": "AIzaSyABvFLtT4eprFwakTWjKaq0yeuKG3oOd10",
        "authDomain": "remoto-2.firebaseapp.com",
        "projectId": "remoto-2",
        "appId": "1:421530974832:web:dad01c47cefed514",
    }, 
    "caser": {
        "apiKey": "AIzaSyAB0pH-vHsji3_tR4xqEl8ZaZ7xvZ0ZOEI",
        "authDomain": "the-good-driver.firebaseapp.com",
        "projectId": "the-good-driver",
        "appId": "1:258587131765:web:568bc9a70085482e2c1d3c"
    },
    "caj": {
        "apiKey": "AIzaSyCT4ThPDa78PR6u9B_1cO-YGmJLfg7KXi0",
        "authDomain": "caser-autos-jovenes.firebaseapp.com",
        "projectId": "caser-autos-jovenes",
        // storageBucket: "caser-autos-jovenes.appspot.com",
        // messagingSenderId: "88680457821",
        "appId": "1:88680457821:web:58ed8852681009820ccfb4",
        // measurementId: "G-GS5GRT6MZC"
    },
    "casercc": {
        "apiKey": "AIzaSyDyxyFHydpDOwafNyaL4NhG5UFmYYlycvw",
        "authDomain": "caser-cc.firebaseapp.com",
        "projectId": "caser-cc",
        "appId": "1:1060857729405:web:eb0d9488e7f85fc1047c14",
    }
    //TODO: este panel solo tiene en los dominios dados de alta: ahp, caj, tgd, rep y snowfy. 
    //RACVN, Remoto2 y CaserCC no estan como dominios, igual se podrían quitar de aqui... o habrá que meterlos en Hosting si migramos a este panel... revisado su funcionamiento ok por si acaso...
};

export default BDS;
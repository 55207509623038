import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
//import { withRouter } from "react-router";

import { Layout, Col, Row, Form, Input, Button } from "antd";
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { useForm } from "antd/es/form/Form";

import { useFirebaseAuth } from "../context/AuthContext";
import {
    app, getAuth, logOut, setSessionID, signInWithEmailAndPassword
} from "../context/firebaseConfig";
import { getPrj } from "../constants/methods";

//import Error from '../components/Error'

import '../assets/css/login.css'
import moment from "moment";

const Login = (props) => {
    const nav = useNavigate();
    const loc = useLocation();
    const { t } = useTranslation();
    const { Footer } = Layout;
    const [form] = useForm();
    const [error, seterror] = useState('')
    const [auth, setAuth] = useState();
/* 
    useEffect(() => {
        if (user && auth.currentUser) {
           // history.push("/private");
        }
    }, [history, user]);
 */
    useEffect(()=> {
        // window.localStorage.clear(); //TODO:DUDA ¿pq estamos limpiando localStorage aqui? comentado funciona 
        setAuth(getAuth(app));
    }, []);


    const FIRST_PAGE = '/pittpanel'; // '/dashboard';
    const withEmail = async e => {
        //e.preventDefault();
        const { user, password } = e;
        await signInWithEmailAndPassword(auth, user, password)
            .then(async result => {
                let to = (window.history.state && window.history.state.idx > 0) ? -1 : FIRST_PAGE;
                if(loc.pathname === '/login') to = FIRST_PAGE;
                // Actualizar el sessionID de la base de datos
                await setSessionID({user: auth?.currentUser?.email, sessionID: +new Date});
                // return nav(to); lo gestionamos según el rol del usuario y dirigir a una page u otro
                // Obtener la pagina a direccionar según rol de usuario:
                /* const roles = result?.user?.rol || []; 
                if (roles.includes('ADMIN')) { // si rol "ADMIN" está presente en el array de roles
                    // return nav('/pittpanel');
                    to = '/pittpanel'; // usuario es administrador, dirigirlo a una página específica para administradores
                } else if (roles.includes('MANAGEMENT')) { // si rol "MANAGEMENT" está en el array
                    to= '/dashboard'; // usuario es gestor, a página gestores
                } else if (roles.includes('OPERATOR')) { // si rol "OPERATOR" está en el array
                    to = '/mapped'; // usuario es operador, a página operadores
                } else {
                    to = '/login'; // sin un rol específico, dirigirlo a una página por defecto
                } */
                //Solo con MenuInterceptor el caso de OPERATOR no lo abre, pq desde aqui le pasa pittpanel como prop
                const roles = result?.user?.rol || []; 
                if (roles.includes('OPERATOR')) { to = '/mapped'; }
                return nav(to);      
            })
            .catch(error => {
                console.log(error);                
                // seterror(t(error.code));
                if((error.code).includes("auth/invalid-login-credentials")){ 
                    seterror(t('ERRMSG_INVALID_CREDENTIALS')); // Credenciales no válidas
                } else if((error.code).includes("auth/invalid-email")){
                    seterror(t('ERRMSG_INVALID_EMAIL')); // Email no válido
                } else{
                    seterror(t(error.code));
                };
            });
    };

    return (
        <Layout style={{ height: "90vh" }}>
            <Row justify='center' align='middle' style={{ height: "80vh" }}>
                <Col span={8} style={{ "textAlign": "center"}}>
                    <img src={`/assets/img/${getPrj()}/logo.png`} alt="" />
                </Col>
                <Col span={8} style={{ 
                    display: "flex", 
                    "justifyContent": "center"
                    }}>
                    <div className='d-flex bg-light align-items-center text-center login-container'
                        style={{
                            padding: 24,
                            width: 400
                        }}
                    >
                        {(
                            <Form className="login-form" onFinish={withEmail} form={form}>
                                <Form.Item>
                                    <h1 className='text-primary'>{t('LOGIN')}</h1>
                                </Form.Item>
                                { error ? 
                                    <Form.Item style={{ color: "rgba(240,0,0,1)" }}> {error} </Form.Item> 
                                    : 
                                    null
                                }
                                <Form.Item
                                    name="user"
                                    rules={[{ required: true, message: t('ERRMSG_FIELD_REQUIRED') }]}
                                >
                                    <Input 
                                        prefix={<UserOutlined  style={{ color: "rgba(0,0,0,.25)" }} /> } 
                                        placeholder={t('USERNAME')} 
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="password"
                                    rules={[{ required: true, message: t('ERRMSG_FIELD_REQUIRED') }]}
                                >
                                    <Input.Password
                                        prefix={
                                            <LockOutlined style={{color: "rgba(0,0,0,.25)"}}
                                            />
                                        }
                                        type="password"
                                        placeholder={t('PASSWORD')}
                                    />
                                </Form.Item>
                                <Form.Item>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        className="login-form-button"
                                        style={{ marginRight: 10 }}
                                    >
                                        {t('BUTTON_LOGIN')}
                                    </Button>
                                </Form.Item>
                            </Form>
                        )}
                    </div>
                </Col>
            </Row>
            <Footer className="fixed-bottom d-none d-sm-block" style={{ textAlign: "center" }}>
                <span>version: 2.0.5 </span> Powered by <a href='https://kaps.es'> Kaps Management Group</a>
            </Footer>
        </Layout>
    );
};
export default Login;
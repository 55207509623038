import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { GoogleMap, Marker } from '@react-google-maps/api';

import { getHook } from "../context/firebaseConfig";
import { useFirebaseAuth } from "../context/AuthContext";

import { useGoogleMaps } from '../context/GoogleMapsContext';

const containerStyle = {
    width: '100%',
    height: '90vh'
  };

  
const MappedAlerts = () => {
    const nav = useNavigate();
    const [user] = useState(useFirebaseAuth());
    const [markers, setMarks] = useState([]);
    //Establecemos aqui el mapa de España completo por si no hubiese coordenadas y q no de error setCenter
    const [center, setCenter] = useState({}); //{lat: 34.5, lng: -3.5}
    const [zoom, setZoom] = useState(); //5.5
    const [mapType, setMapType] = useState(); //5.5
    const [customMap, setCustomMap] = useState(null);
    const mapRef = useRef(null);
      
    const { isLoaded } = useGoogleMaps();
    
    useEffect(() => {
        // desde el contexto establecemos las coordenadas del mapa a mostrar
        setCenter(user?.configs?.mapCenter);
        setZoom(user?.configs?.mapZoom);
        setCustomMap(user?.configs?.customMap);  
        setMapType(user?.configs?.mapType);
    }, [user])

    useEffect(() => {
        const unsubscribe = getHook({coll: 'alerts', order: 'alertId', service: user.service, lim: 100},
            (querySnapshot) => {
                const lst = querySnapshot.docs.map(docSnapshot => docSnapshot.data());
                setMarks(lst);
            },
            (error) => alert('kk de la vaca')
        );
        return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setMarks]);

    const onLoad = (map) => {
        mapRef.current = map;
        if (mapRef.current && customMap) {
            const map = mapRef.current;
      
            // Define las coordenadas de los límites de la imagen
            const imageBounds = {
              north: customMap.north,
              south: customMap.south,
              east: customMap.east,
              west: customMap.west
            };
      
            // Crea el GroundOverlay
            const groundOverlay = new window.google.maps.GroundOverlay(
              customMap.image, // Reemplaza con la URL de tu imagen GIF
              imageBounds
            );
      
            // Agrega el GroundOverlay al mapa
            groundOverlay.setMap(map);
          }
    };

    return (
        <React.Fragment>
            {isLoaded &&
                <GoogleMap
                    mapTypeId={mapType}
                    mapContainerStyle={containerStyle}
                    center={center}
                    zoom={zoom}
                    onLoad={onLoad}
                    //onUnmount={onUnmount}
                >
                    {markers.map(l => (
                        l.visible &&
                        <Marker 
                            position={{lat:l.latitude || l.lat, lng:l.longitude || l.long}} 
                            icon={`/assets/img/${l.viewed?'':'no'}visto.gif`} 
                            onClick={() => nav('/watch', { state: { data:l, type: 'alerts', petet:'si'}})}
                        />
                    ))}
                </GoogleMap>
            }
        </React.Fragment>
    )
}

export default MappedAlerts;
import React, { Fragment, useEffect, useState } from "react";

import { Button, Card, Col, Collapse, Form, Image, Input, Layout, List, Row, Space, Spin } from "antd";
import { Content } from "antd/lib/layout/layout";
import { CaretRightOutlined } from '@ant-design/icons';

import { useTranslation } from "react-i18next";

import MyHeader from "../components/Header";
import MyMenu from "../components/Menu";
import { callApi, getDocu, getDocus } from "../context/firebaseConfig";

import '../assets/css/main.css';
import HorizontalBar from "../components/statistics/horizontalBar";
import LineChart from "../components/chart/LineChart";
import TrendingChart from "../components/statistics/trendingChart";
import SoapBar from "../components/statistics/soapBar";
import { useFirebaseAuth } from "../context/AuthContext";

const UserTravels = (props) => {
    const { t } = useTranslation();
    const [user] = useState(useFirebaseAuth());
    const [travels, setTravels] = useState([]);
    const [sc, setScore] = useState(null);
    const [userGlobalResume, setUserGlobalResume] = useState(null);
    const [userGlobalTrending, setUserGlobalTrending] = useState({});
    const [userGlobalData, setUserGlobalData] = useState({});
    const [icon, setIcon] = useState(null);
    const [travelData, setTravelData] = useState();
    const [loadingTravelData, setLoadingTravelData] = useState(false);
    const [numTravels, setNumTravels] = useState();

    const collapseStyle = {
        marginBottom: 24,
        background: 'white',
        borderRadius: 10,
        border: 'none',
    }

    const getTravels = async (e) => {
        setScore(null);
        setUserGlobalData(null);
        setUserGlobalResume(null);
        setUserGlobalTrending(null);
        setTravelData(null);
        setNumTravels(0);
        const user = await getDocu({coll: 'users', docu: e.user});
        setScore(user.score);
        const travelos = await getDocus({coll: `users/${e.user}/travels-cache`});
        try {
            let petete = [];
            travelos.map(item => { 
                const ini = new Date(item.it);
                // limitamos la visualización a los 3 meses naturales o sea new Date()
                const d = new Date();
                const dini =+new Date(`${d.getFullYear()}-${d.getMonth()-1}-1 00:00:00`)
                if(ini > dini) {
                    const end = new Date(item.et);
                    const date = `${ini.getDate()}-${ini.getMonth()+1}-${ini.getFullYear()}, 
                                    ${ini.getHours()}:${ini.getMinutes().toString().padStart(2, '0')}
                                    -${end.getHours()}:${end.getMinutes().toString().padStart(2, '0')}`;
                    const icon  = item.sc >= 80 ? 'bueno' : item.sc < 60 ? 'malo' : 'feo';
                    
console.log(item.wi);                    
                    petete.push(
                        <List.Item onClick={() => viewTravel(item, e.user) } style={{borderBottom: '1px solid grey'}}>
                            <List.Item.Meta key={item.it}
                                avatar={<Image width={80} src={`assets/img/caj/${icon}.png`} preview={false} />}
                                // title={`${item.dt} km`}
                                description={
                                    <Fragment>
                                        <div style={{fontSize: '10px !important', display: 'flex'}}>
                                            <div style={{ flex: 1 }}>
                                                <p>{date}<br/>{item.dt} km</p>
                                                <p>{item.ippc} {item.iplo}<br/>{item.eppc} {item.eplo}</p>
                                            </div>
                                            { item.wd && 
                                                <div style={{flexBasis: '40%', flexShrink: 0, textAlign:'center'}}>
                                                    <p><img src={item.wi} alt='tgd' style={{maxHeight: '55px'}} /> <br/>
                                                    <span>{item.wd.charAt(0).toUpperCase() + item.wd.slice(1)} </span></p>
                                                </div>
                                            }
                                        </div>
                                    </Fragment>
                                }
                            />
                        </List.Item>); 
                }
            });
            setNumTravels(petete.length);
            setTravels([...petete]);
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        try {
            // Icono global
            const score = (sc.score/sc.scoreCounter).toFixed(0);
            setIcon(score >= 80 ? 'bueno' : score < 60 ? 'malo' : 'feo');
            // Resumen global
            setUserGlobalResume([{
                key: '1',
                label: t('CARD_TITLE_RESUME'),
                children:<>
                    <HorizontalBar title='LABEL_SPEEDING' percent={(sc.excessSpeed/sc.excessSpeedCounter).toFixed(2)}/>
                    <HorizontalBar title='ACCELERATION' percent={(sc.accelerations/sc.accelerationsCounter).toFixed(2)} />
                    <HorizontalBar title='BRAKING' percent={(sc.brakings/sc.brakingsCounter).toFixed(2)} />
                    <HorizontalBar title='PHONE_USAGE' percent={(sc.phoneUsages/sc.phoneUsagesCounter).toFixed(2)} />
                    <HorizontalBar title='CURVE' percent={(sc.curves/sc.curvesCounter).toFixed(2)} />
                </>,
                style: collapseStyle
                    
            }]);
            // Graficas de las tendencias  <TrendingChart values={sc['excessSpeed-tracker']} title={t('SPEEDING_LABEL')} />
            setUserGlobalTrending([{
                key: '1',
                label: t('TRENDING'),
                children:<>
                    <TrendingChart values={sc['excessSpeed-tracker']} title={t('LABEL_SPEEDING')} />
                    <TrendingChart values={sc['accelerations-tracker']} title={t('ACCELERATION')} />
                    <TrendingChart values={sc['brakings-tracker']} title={t('BRAKING')} />
                    <TrendingChart values={sc['phoneUsages-tracker']} title={t('PHONE_USAGE')} />
                    <TrendingChart values={sc['curves-tracker']} title={t('CURVE')} />
                </>,
                style: collapseStyle
                    
            }]);
            // Datos de las medias
            setUserGlobalData([{
                key: '1',
                label: t('DATA'),
                children:
                    <Row gutter={25} justify='space-around' style={{ marginRight: '5px'}}>
                        <SoapBar detail='NUM_TRAVELS' value={sc.scoreCounter} unit='nº' />
                        <SoapBar detail='AVERAGE_DISTANCE' value={(sc.distance/sc.distanceCounter).toFixed(0)} unit='km' />
                        <SoapBar detail='AVERAGE_DURATION' value={(sc.duration/sc.durationCounter).toFixed(0)} unit='min' />
                        <SoapBar detail='AVERAGE_MAX_SPEED' value={(sc.maxSpeed/sc.maxSpeedCounter).toFixed(1)} unit='km/h' />
                        <SoapBar detail='AVERAGESPEED_RESULT' value={(sc.averageSpeed/sc.averageSpeedCounter).toFixed(1)} unit='km/h' />
                    </Row>
                ,
                style: collapseStyle
            }]);
        } catch (error) {
            
        }
    },[sc]);
    
    const viewTravel = async (travel, searched) => {
        setTravelData(null);
        setLoadingTravelData(true);
        const data = {
            tk : user?.accessToken,
            service : user.service,
            module : 'travels',
            method : 'getScoring',
            it : travel.it,
            searched
        };
        const travelScore = await callApi(data);
        const results = {};
        const petete = [];
        travelScore.map(ele => {
            if( ele.type == 'SCTAB' ) {
                const json = JSON.parse(ele.extendedValue);
                results[json.id] = json;
            } else if( ele.type == 'OVTAB' ) {
                const json = JSON.parse(ele.extendedValue);
                if(json.id != 'SCORE') {
                    petete.push(
                        <List.Item style={{borderBottom: '1px solid grey'}}>
                            <List.Item.Meta key={json.id}
                                description={t(json.id)}
                            />
                            <div>{json.v2}</div>
                        </List.Item>
                    ); 
                }
            }
        })
        const stc = 
        <>
            <Content style={{paddingTop: '20px', fontStyle: 'italic'}}>{t('CARD_TITLE_RESUME')}</Content>
            <HorizontalBar title='LABEL_SPEEDING' percent={results.EXCESS_SPEED.v2.replace(/%/, '')}/>
            <HorizontalBar title='ACCELERATION' percent={results.ACCELERATIONS.v2.replace(/%/, '')} />
            <HorizontalBar title='BRAKING' percent={results.BRAKINGS.v2.replace(/%/, '')} />
            <HorizontalBar title='PHONE_USAGE' percent={results.PHONE_USAGES.v2.replace(/%/, '')} />
            <HorizontalBar title='CURVE' percent={results.CURVES.v2.replace(/%/, '')} />
            <Content style={{paddingTop: '20px', fontStyle: 'italic'}}>{t('DATA')}</Content>
            {petete}
        </>
        setTravelData(stc);
        setLoadingTravelData(false);
    }


    return (
        <React.Fragment>
            {/* <Layout>
                <MyHeader title={t('BUTTON_USER_TRAVELS')} key={`MyHeader_${+new Date()}`} />
                <Content style={{display:"flex"}}>
                    <MyMenu key={`MyMenu_${+new Date()}`}/> */}
                    <div style={{margin: "0 auto", width: "90%", paddingTop:"20px"}}>
                        <Row >
                                <Form onFinish={getTravels} >
                                    <Form.Item name="user" rules={[{ required: true, message: t('REQUIRED')}]} label={t('LABEL_USER')}>
                                        <Space align="center">
                                            <Input initialvalue=''/>
                                            <Button htmlType="submit" type='primary' >{t('BUTTON_VIEW')}</Button>
                                        </Space>
                                    </Form.Item>
                                </Form>
                        </Row>
                        <Row gutter={10}>
                            <Col className="gutter-row" xs={8} >
                                { travels.length > 0 && 
                                    <>
                                        <Content>{`${t('LABEL_TRAVELS_LIST')} (${numTravels})`}</Content>
                                        <List
                                            bordered={true}
                                            itemLayout="horizontal"
                                            size="small"
                                            style={{height: '80vh', maxHeight: '90vh', overflowY: 'scroll'}}
                                            >
                                            {travels}
                                            
                                        </List>
                                    </>
                                }
                            </Col>
                            <Col xs={8} >
                                {
                                    loadingTravelData && <Spin style={{ height: '50vh',  display: "flex", justifyContent: "center", alignItems: "center" }}/>
                                }
                                { travelData && 
                                    <>
                                        <Content>{t('LABEL_DATA_TRAVEL')}</Content>
                                        {travelData}
                                    </>
                                }
                            </Col>
                            <Col xs={8} >
                                { userGlobalResume != null && 
                                    <>
                                        <Content>{t('LABEL_GLOBAL_STATISTICS')}</Content>
                                        <Image width={'90%'} src={`assets/img/caj/${icon}.png`} preview={false} /> 
                                        <Collapse 
                                            defaultActiveKey={['0']} 
                                            bordered={false}
                                            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                                            items={userGlobalResume}
                                        />
                                    </>
                                }
                                { userGlobalData != null && 
                                    <>
                                        <Collapse 
                                            defaultActiveKey={['0']} 
                                            bordered={false}
                                            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                                            items={userGlobalData}
                                        />
                                    </>
                                }
                                { userGlobalTrending != null && 
                                    <>
                                        <Collapse 
                                            defaultActiveKey={['0']} 
                                            bordered={false}
                                            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                                            items={userGlobalTrending}
                                            style={{height: '50vh', maxHeight: '60vh', overflowY: 'scroll'}}
                                        />
                                    </>
                                }
                            </Col>
                        </Row>

                    </div>
               {/*  </Content>
            </Layout> */}
        </React.Fragment>
    )
}

export default UserTravels;
import { Input, Modal } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
// import Tooltip from 'antd/es/tooltip';
import { /* InfoCircleOutlined, */ MailOutlined }  from "@ant-design/icons";

import { useTranslation } from 'react-i18next'; 
import { updateDocu } from '../context/firebaseConfig';

const ModalSimCreated = (props) => {
    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState();
    const inputRef = useRef(null)

    useEffect(()=> {
        setIsModalOpen(props.open);
    }, [props.open])

    const handleOk = async () => {
        const mail = inputRef?.current?.input?.value;
        const re = new RegExp(/(\w.)+@\w.\w/)
        if(mail && re.test(mail)) {
            await updateDocu({ coll: 'simulations', docu: props.docId, data: { email: mail } });
        }
        let p = {...props}
        p.open = false;
        props.callbackModal(p);
        setIsModalOpen(false);
    };

    return (
        <>
        <Modal title={`${t('SIMULATION_MAIL_FINISHED')}`} open={isModalOpen} onOk={handleOk} cancelButtonProps={{ style: {display: "none"} }}>
            <p>{t(props.message)}</p>
            <p>
                { props.docId && 
                    <Input
                        type='email'
                        placeholder={`${t('ENTER_MAIL')}`}
                        prefix={<MailOutlined className="site-form-item-icon" />}
                        ref={inputRef}
                    />
                }
            </p>
        </Modal>
        </>
    );
};
export default ModalSimCreated;
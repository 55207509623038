import React, { useState } from 'react'
import i18resources from '../../i18resources'
import i18next from "i18next"
import { Dropdown, Button } from 'antd'
import 'flag-icon-css/css/flag-icons.min.css'


const LanguageSelector = () => {
    const [currentLang, setCurrentLang] = useState(localStorage.getItem('i18nextLng') || 'en');
    // const [items, setItems] = useState([]);
    
    const changeLang = (code) => {
        setCurrentLang(code);
        i18next.changeLanguage(code);
        // window.location.reload();
    }

    const items =  i18resources.langList.map(({code, name, country_code}) => (
        {
            key: code,
            label: (
                <div onClick={() => changeLang(code)}>
                    <span className={`flag-icon flag-icon-${country_code} mx-2`}></span>
                    <span style={{ paddingLeft: '5px' }}>{name}</span>
                </div>
            ),
            disabled: currentLang === code
        }
    ));
    
    const DropdownMenu = () => (
    
        <Dropdown key="languageSelector" menu={{items}}>
            <Button shape='circle' key='petete' icon={<i18resources.GlobeIcon2 />} style={{ "backgroundColor": "#001529"}}/>
        </Dropdown>
    );
    
    return(<DropdownMenu />)
}

export default LanguageSelector;
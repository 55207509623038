import React, { useEffect, useState } from "react";
import { GoogleMap, HeatmapLayer } from '@react-google-maps/api';

import { getHeatMap } from "../context/firebaseConfig";
import { useFirebaseAuth } from "../context/AuthContext";

import { ConfigProvider, DatePicker, Layout } from 'antd';
import locale from 'antd/locale/es_ES';

import { useGoogleMaps } from '../context/GoogleMapsContext';
import dayjs from 'dayjs';
import Loading from '../components/Loading';

const { Content } = Layout;

const containerStyle = {
    width: '100%',
    height: '94vh'
};

const actionsMenuWrapper = {
    position: 'absolute',
    'zIndex': '10',
    top: '80px',
    left: '45%',
}

const FORMAT = 'YYYY-MM-DD HH';
  
const HeatMap = () => {
    const [user] = useState(useFirebaseAuth());
    const [markers, setMarks] = useState([]);
    //Establecemos aqui el mapa de España completo por si no hubiese coordenadas y q no de error setCenter
    const [center, setCenter] = useState({}); //{lat: 34.5, lng: -3.5}
    const [zoom, setZoom] = useState(); //5.5
    const [selectedDate, setSelectedDate] = useState();
    const [isLoading, setLoading] = useState(true);

    const { isLoaded } = useGoogleMaps();
    
    useEffect(() => {
        // desde el contexto establecemos las coordenadas del mapa a mostrar
        setCenter(user?.configs?.mapCenter);
        setZoom(user?.configs?.mapZoom);
    }, [user])

    useEffect(() => {
        const d = dayjs();      
        setSelectedDate(d);
        getData(d.format(FORMAT));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    const getData = async (dateString) => {
        setLoading(true);
        const ini = new Date(`${dateString}:00:00`).getTime();
        const end = new Date(`${dateString}:59:59`).getTime();
        let data = {};
        data.tk = user?.accessToken;
        data.service = user?.service;
        data.ini = ini;
        data.end = end;
        const ret = await getHeatMap(data);
        setMarks(ret);
        setLoading(false);
    }

    const onChange = async (value, dateString) => {
        // Procesamos la fecha dada y hacemos el setMarks
        setSelectedDate(value);
        await getData(dateString);
    };

    return (
        <React.Fragment>
            <Layout>
                { isLoading ? 
                        <Loading />
                    :
                        <Content>
                            <div style={actionsMenuWrapper}>
                                <ConfigProvider locale={locale}>
                                    <DatePicker
                                        showTime
                                        // defaultValue={dayjs().format(FORMAT)}
                                        onChange={onChange}
                                        value={selectedDate}
                                        format={FORMAT}
                                        size='large'
                                    />
                                </ConfigProvider>
                            </div>
                            { isLoaded &&
                                <GoogleMap
                                    mapContainerStyle={containerStyle}
                                    center={center}
                                    zoom={zoom}
                                >
                                    {markers.length > 0 && (
                                        <HeatmapLayer
                                            data={markers.map(
                                                point => ({ location: new window.google.maps.LatLng(point.lat, point.lng||point.lon),
                                                weight: point.weight })
                                            )}
                                            radius={20}
                                        />
                                    )}                      
                                </GoogleMap>
                            }
                        </Content>
                }
            </Layout>
        </React.Fragment>
    )
}

export default HeatMap;